import logo from '../assets/logo.png';
import { AiOutlineMenu, AiFillEye, AiFillContacts, AiOutlineClose, AiFillHome } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom'
import { MdLocalOffer } from 'react-icons/md';
import { BiBullseye } from 'react-icons/bi';
import { PiStrategyBold } from 'react-icons/pi';
import { useState } from 'react';

const Navbar = () => {
    const [toggle, setToggle] = useState(false)

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <nav>
            <Link to="/"><img src={logo} alt="logo" height='64' /></Link>
            {toggle ? <AiOutlineClose onClick={() => setToggle(!toggle)} /> : <AiOutlineMenu id="menu" onClick={() => setToggle(!toggle)} />}
            {toggle &&
                <ul>
                    <NavLink to="/" onClick={handleLinkClick} activeClassName="active"><AiFillHome />Homepagina</NavLink>
                    <NavLink to="/ons-aanbod" onClick={handleLinkClick} activeClassName="active"><MdLocalOffer />Ons Aanbod</NavLink>
                    <NavLink to="/onze-visie" onClick={handleLinkClick} activeClassName="active"><AiFillEye />Onze Visie</NavLink>
                    <NavLink to="/onze-missie" onClick={handleLinkClick} activeClassName="active"><BiBullseye />Onze Missie</NavLink>
                    <NavLink to="/onze-strategie" onClick={handleLinkClick} activeClassName="active"><PiStrategyBold />Onze Strategie</NavLink>
                    <NavLink to="/contact-gegevens" onClick={handleLinkClick} activeClassName="active"><AiFillContacts />Contact Gegevens</NavLink>
                </ul>
            }
            <ul id="desktop-nav">
                <NavLink to="/" onClick={handleLinkClick} activeClassName="active"><AiFillHome />Homepagina</NavLink>
                <NavLink to="/ons-aanbod" onClick={handleLinkClick} activeClassName="active"><MdLocalOffer />Ons Aanbod</NavLink>
                <NavLink to="/onze-visie" onClick={handleLinkClick} activeClassName="active"><AiFillEye />Onze Visie</NavLink>
                <NavLink to="/onze-missie" onClick={handleLinkClick} activeClassName="active"><BiBullseye />Onze Missie</NavLink>
                <NavLink to="/onze-strategie" onClick={handleLinkClick} activeClassName="active"><PiStrategyBold />Onze Strategie</NavLink>
                <NavLink to="/contact-gegevens" onClick={handleLinkClick} activeClassName="active"><AiFillContacts />Contact Gegevens</NavLink>
            </ul>
        </nav>
    )
}

export default Navbar